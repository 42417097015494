<template>
  <div class="archive-rent-list">
    <div class="container fluid">
      <h1 class="my-4 mx-2">Archived Rent</h1>
      <!-- <filter-panel
        v-model="isFilterActive"
        useToggle
        :isLoading="isFilterLoading"
        :loadingItems="1"
        @filter="filterData"
        @clear="clearFilter"
      >
        <div class="p-2">
          <fd-select
            v-model="filter['status:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Status"
            :options="statusOptions"
            :optionLabel="(val) => val.name"
            :optionValue="(val) => val.id"
          >
          </fd-select>
        </div>
      </filter-panel> -->
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="archiveRentTableColumns"
          :rows="archiveRentTableData"
          :totalRows="archiveRentTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false,
            initialSortBy: { field: 'id', type: 'desc' }
          }"
          @on-row-click="toDetail"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Actions -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card">
                  <list-item @click="toEdit(props.row.id)">
                    <i class="fas fa-pen mr-1"></i> Edit
                  </list-item>
                  <list-item @click="downloadTA(props.row.id)">
                    <i class="fas fa-file-export mr-1"></i> Download TA
                  </list-item>
                </div>
              </dropdown-button>
            </span>
            <!-- ID -->
            <span v-if="props.column.field == 'archiveId'" @click.stop>
              <copy-button :value="props.row.id" :style="{ width: '80px' }">
              </copy-button>
            </span>
            <!-- Property -->
            <property-summary
              v-if="props.column.field == 'propertySummary'"
              :property="props.row.property"
              address
            >
            </property-summary>
            <span
              v-else-if="props.column.field == 'rentalPrice'"
              class="font-bold"
            >
              RM
              {{
                numberWithCommas(
                  parseInt(props.row.tenancyAgreement.monthlyRent)
                )
              }}
            </span>
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { rentBookingStatus } from "@/enums";
import {
  getArchiveRents,
  downloadTenancyAgreement
} from "@/modules/Rent/api/archiveRent";
import { numberWithCommas } from "@/utils/string";

export default {
  name: "ArchiveRent",
  mixins: [vueGoodTable],
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    CopyButton: () => import("@/components/GlobalComponents/CopyButton"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    ListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  data() {
    return {
      archiveRentTableData: [],
      archiveRentTablePagination: {
        total: 0
      },
      archiveRentTableColumns: [
        {
          label: "",
          field: "actions"
        },
        {
          label: "ID",
          field: "archiveId"
        },
        {
          label: "Landlord",
          field: (row) => row.tenancyAgreement.landLordDetail.users[0].name
        },
        {
          label: "Tenant",
          field: (row) => row.tenancyAgreement.tenantDetail.users[0].name
        },
        {
          label: "Property",
          field: "propertySummary"
        },
        {
          label: "Monthly Rent",
          field: "rentalPrice"
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (data) => this.$dayjs(data).format("DD-MM-YYYY")
        },
        {
          label: "Created At",
          field: "createdAt",
          formatFn: (data) => this.$dayjs(data).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      isFilterLoading: false,
      filter: {},
      statusOptions: [],
      rentBookingStatus,

      numberWithCommas
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getAllArchiveRent()]);
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },
    toDetail(event) {
      this.$router.push({
        name: "ArchivePropertyRentDetail",
        params: { id: event.row.id }
      });
    },
    toEdit(id) {
      this.$router.push({
        name: "ArchivePropertyRentEdit",
        params: { id: id }
      });
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllArchiveRent();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllArchiveRent();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllArchiveRent();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllArchiveRent();
    },
    // ========================== API Related =========================
    async getAllArchiveRent() {
      this.$store.commit("setIsLoading", true);
      try {
        // Remove empty string from filter
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await getArchiveRents({
          queries: {
            ...filteredParam
          },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.archiveRentTableData = this._.cloneDeep(data.data);
        this.archiveRentTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async downloadTA(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await downloadTenancyAgreement(id);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to download tenancy agreement. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>

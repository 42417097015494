var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"archive-rent-list"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"my-4 mx-2"},[_vm._v("Archived Rent")]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.archiveRentTableColumns,"rows":_vm.archiveRentTableData,"totalRows":_vm.archiveRentTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false,
          initialSortBy: { field: 'id', type: 'desc' }
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('dropdown-button',{attrs:{"buttonClass":"circle"},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('div',{staticClass:"card"},[_c('list-item',{on:{"click":function($event){return _vm.toEdit(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen mr-1"}),_vm._v(" Edit ")]),_c('list-item',{on:{"click":function($event){return _vm.downloadTA(props.row.id)}}},[_c('i',{staticClass:"fas fa-file-export mr-1"}),_vm._v(" Download TA ")])],1)])],1):_vm._e(),(props.column.field == 'archiveId')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('copy-button',{style:({ width: '80px' }),attrs:{"value":props.row.id}})],1):_vm._e(),(props.column.field == 'propertySummary')?_c('property-summary',{attrs:{"property":props.row.property,"address":""}}):(props.column.field == 'rentalPrice')?_c('span',{staticClass:"font-bold"},[_vm._v(" RM "+_vm._s(_vm.numberWithCommas( parseInt(props.row.tenancyAgreement.monthlyRent) ))+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }